import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Heading,
  Row,
  Column,
  Image,
  Section,
  Text,
  Span,
} from 'components'
import Promo from 'images/blogs/blog-3.jpg'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/6-na-paraan-kung-paano-kumita-gamit-ang-digipay-app`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '6 Na Paraan Kung Paano Kumita Gamit Ang Digipay App',
  },
  {
    property: 'og:description',
    content: 'Alamin ang mga paraan kung paano kumita gamit ang Digipay app!',
  },
  {
    property: 'og:image',
    content: Promo,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="6 Na Paraan Kung Paano Kumita Gamit Ang Digipay App"
      meta={SITE_META}
    >
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Promo} />
        <Heading
          className="blog__title"
          mb="sp4"
          textAlign="center"
          fontWeight="bold"
        >
          6 NA PARAAN KUNG PAANO KUMITA GAMIT ANG DIGIPAY APP
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            BILLS PAYMENT
          </Heading>
          <Text>
            Kahit saang lugar ka pa naroon, pwedeng-pwede kang tumanggap ng
            bayad para sa iba’t-ibang water and electric companies katulad ng{' '}
            <Span fontWeight="bold">
              MERALCO, BATELEC 1 at 2, QUEZELCO, Manila Water, Maynilad,{' '}
            </Span>{' '}
            at iba pa. Kung may ka-opisina o kaibigan ka namang kailangang
            magbayad ng credit card, mas mapapadali na ang buhay nila kapag sa
            Digipay sila nagbayad. Mayroon ding loan payments katulad ng{' '}
            <Span fontWeight="bold">Cashalo</Span> at{' '}
            <Span fontWeight="bold">Home Credit.</Span> Kasama din sa listahan
            ang mga government agencies sa mga pwedeng bayaran kaya naman hindi
            ka mawawalan ng customers kapag naging agent ka.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            E-LOAD
          </Heading>
          <Text>
            Halos lahat kailangan ng load kaya buhay na buhay ang business mo
            dahil pwede ding mag-load gamit ang Digipay app. Mayroong{' '}
            <Span fontWeight="bold">
              Globe, Smart, Sun, TM, Red Mobile, Cignal, at ABS-CBN{' '}
            </Span>{' '}
            na kasama sa mga pagkakakitaan mo. Gamit lang ang iyong mobile phone
            o kaya naman laptop, kikita ka na kahit nakaupo ka lang sa bahay.
            Ganyan kadali ang buhay kapag may Digipay ka sa iyong tindahan.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            E-PINS
          </Heading>
          <Text>
            Kung may computer shop ka at marami kang kapitbahay na gamer, sulit
            na sulit ang Digipay dahil makakabili sila sayo ng gaming pins
            katulad ng{' '}
            <Span fontWeight="bold">
              Airsoft, Blizzard, Cherry Credits, Cubizone, Eagle Points, EX
              Cash, Garena, Level up
            </Span>{' '}
            at marami pang iba. Hindi na nila kailangan pang lumayo kaya
            siguradong dadagsa talaga ang benta mo kung kasama ang Digipay sa
            business mo.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MOBILE MONEY
          </Heading>
          <Text>
            Pera padala ba ang hanap mo? Kumpleto ‘yan kapag naging Digipay
            agent ka. Mayroong <Span fontWeight="bold">GCash, PayMaya </Span>at{' '}
            <Span fontWeight="bold">Smart Money.</Span> Ang maliit mong
            tindahan, magiging digi-tindahan sa sobrang high-tech ng mga pwede
            mong pagkakitaan gamit ang Digipay app. Hindi na mahihirapang
            bumyahe ang mga tao sa barangay niyo para lang magpadala sa kanilang
            mga mahal sa buhay.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MICROINSURANCE
          </Heading>
          <Text>
            Sa dami ng produktong pwedeng ibenta, pati health insurance meron
            na. Makakabili na ng <Span fontWeight="bold">HealthGuard</Span> ang
            iyong mga ka-barangay sa halagang P100. Pwede ito mula sa mga 18-64
            taong gulang kaya naman maiaalok mo ito sa mga pamilyang
            binabantayan ang kanilang kalusugan at kaligtasan.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REBATES AND CONVENIENCE FEE
          </Heading>
          <Text>
            Sobra sobra ang saya sa Digipay dahil kada transaction, may rebates
            at convenience fee ka. Siguraduhing may kopya ka ng rate sheet kapag
            nag-sign up ka para malaman mo ang lahat ng rebates sa lahat ng
            klase ng transaction. Mas marami at malaking transaction, mas mataas
            na rebates ang makukuha mo.
          </Text>

          <Text>Ano pang hinihintay mo? Sign up na bilang Digipay agent!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
